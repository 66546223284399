
import { defineComponent } from 'vue';
import IconSpinner from '@/assets/icons/icon-spinner.vue';

const __default__ = defineComponent({
  name: 'AppLoaderBasic',

  components: {
    IconSpinner,
  },

  data() {
    return {
      transitionDuration: 500,
    };
  },

  computed: {
    transition(): string {
      return `opacity ${this.transitionDuration}ms`;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "538e100d": (_ctx.transition)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__