import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7123156a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-modal__header",
  "data-testid": "app-modal-header"
}
const _hoisted_2 = {
  class: "app-modal__body",
  "data-testid": "app-modal-body"
}
const _hoisted_3 = {
  key: 1,
  class: "app-modal__footer",
  "data-testid": "app-modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSeparator = _resolveComponent("AppSeparator")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    "no-route-dismiss": _ctx.noRouteDismiss
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "app-modal",
        style: _normalizeStyle(_ctx.appModalStyle),
        "data-testid": "app-modal"
      }, {
        default: _withCtx(() => [
          (_ctx.showHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "header", { dismiss: _ctx.dismiss }, undefined, true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "body", {}, undefined, true)
          ]),
          _createVNode(_component_AppSeparator),
          (_ctx.showFooter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "footer", { dismiss: _ctx.dismiss }, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["style"])
    ]),
    _: 3
  }, 8, ["modelValue", "no-route-dismiss"]))
}