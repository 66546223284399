<template>
  <Transition name="fade">
    <IconSpinner class="loading-animation__spinner" />
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconSpinner from '@/assets/icons/icon-spinner.vue';

export default defineComponent({
  name: 'AppLoaderBasic',

  components: {
    IconSpinner,
  },

  data() {
    return {
      transitionDuration: 500,
    };
  },

  computed: {
    transition(): string {
      return `opacity ${this.transitionDuration}ms`;
    },
  },
});
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  width: 100%;
  height: 100%;
  transition: v-bind(transition);
}

.fade-enter,
.fade-leave-to {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.loading-animation__spinner {
  width: 100%;
  height: 100%;
  animation: spin 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
