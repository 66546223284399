<script setup lang="ts">
import { v4 } from 'uuid';
import { onBeforeMount, ref } from 'vue';

interface Props {
  disable?: boolean;
}

const props = defineProps<Props>();

const model = defineModel<string>({ required: true });

const input = ref<HTMLInputElement>();

const id = v4();

function handleInputChange(event: Event): void {
  model.value = (event.target as HTMLInputElement).value;
}

onBeforeMount(() => {
  if (model.value === '') {
    model.value = '#ffffff';
  }
});
</script>

<template>
  <label
    :for="id"
    class="app-color-picker"
    data-testid="app-color-picker-label-container"
  >
    <input
      :id="id"
      ref="input"
      type="color"
      name="app-color-picker-input"
      class="app-color-picker__input"
      data-testid="app-color-picker-input"
      :value="model"
      :disabled="props.disable"
      @change="handleInputChange"
    />
    <span class="app-color-picker__label" data-testid="app-color-picker-label">
      {{ model.toUpperCase() }}
    </span>
  </label>
</template>

<style scoped lang="scss">
.app-color-picker {
  display: flex;
  cursor: pointer;
  min-height: 40px;
  max-width: 290px;
  border-radius: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  border: 2px solid $gray-100;
  box-shadow: $neutral-mid-shadow;
  background-color: v-bind(model);
}

.app-color-picker__input {
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
}

.app-color-picker__label {
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  color: transparent;
  background: inherit;
  pointer-events: none;
  background-clip: text;
  filter: invert(1) grayscale(1) contrast(9);
}
</style>
